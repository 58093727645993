<template>
  <v-layout column>
    <!-- <Navbar /> -->
    <v-main>
      <router-view/>
    </v-main>
    <!-- <Footer />
    <mbs-dialog/> 
    <mbs-dialog-input/> 
    <mbs-dialog-progress/>  -->
  </v-layout>
  
</template>

<script>
// import Navbar from './../../components/Navbar.vue'
// import Footer from './../../components/Footer.vue' 

export default {

  name: 'App',
  components: {
  },

  data: () => ({
    //
  }),
  created(){
  },
};
</script>

<style >
@media (min-width: 1264px) {
  .container {
    max-width: 1150px;
  }
}
@media (min-width: 1464px) {
  .container {
    max-width: 1300px;
  }
}
  
</style>
