import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // NavBar:"/components/NavBar.vue",
    kipCountImages:"/images/all/"
  },
  getters: {
    // getNavBar(state) {
    //   return state.NavBar
    // },
    getKipCountImages(state) {
      return state.kipCountImages
    },
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
