import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/pages/Home.vue'
import goTo from 'vuetify/lib/services/goto'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  }
]

const router = new VueRouter({
  scrollBehavior(to, from, savedPosition) {
    let scrollTo = 0 
    if (to.hash) { 
      scrollTo = to.hash 
    } else if (savedPosition) {
      scrollTo = savedPosition.y
    }  
    return goTo(scrollTo,{offset:100,duration:1000})
  },
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
export default router