<template>
<div id="footer">
    <v-footer paddles class="black lighten-4">
        <v-col
            class=" white--text"
            cols="12"
        >
             
        <v-layout row wrap justify-center align-center class="mt-1">
            <span class="pb-3">© {{ new Date().getFullYear() }} — Powered by  </span> 
            <span v-for="(item, index) in logo" :key="index" class="mx-1">                
                <span >
                    <img  :src="item.src"  alt="alt" width="22px" >
                </span>                
            </span> 
            <strong class="pb-3"> Mbera Solutions.</strong>    
            
        </v-layout> 
        </v-col>
    </v-footer>
</div>  
</template>

<script>
export default {
    data: () => ({
        logo: [
            {src:"kipcount/logo_mbs/logo-w2.png"}
        ]
    })
}
</script>

<style>

</style>