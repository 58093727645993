<template> 
    <div class="navbar">
        <v-app-bar        
            color="white" class="px-12 py-2"
            app height=" " elevate-on-scroll
            elevation-10
        >
            <router-link to="/" tag="span">  
                <v-img class="mr-2"   width="70" src="/kipCount/kipcount.jpeg"/>
            </router-link>
            <router-link to="/" tag="span">   
                <v-toolbar-title class="font-weight-">
                   <span class="text-h5 font-weight-">KIP</span><br> 
                   <div class="text-h4">
                       <span 
                           class="primary--text  font-weight-bold"
                        >COUNT</span>
                   </div>
                </v-toolbar-title>
            </router-link>
            <v-spacer></v-spacer>        
            <v-toolbar-items 
                class="hidden-xs-only">
                <div  v-for="(item, i) in menulist"
                    :key="i" class="fill-height"
                >
                    <!-- <v-hover v-slot="{hover}"> -->
                        <v-btn text class="primary--text "
                             href="http://www.pos.kipcount.com" target="parent"
                        >
                            {{item.title}}                  
                            <v-icon color=" " 
                            class="mr- ml-2">{{item.icon}}</v-icon>                    
                        </v-btn>               
                    <!-- </v-hover> -->
                </div>
            </v-toolbar-items>
            <v-menu class="px-0 mx-0"    
                bottom="" 
                offset-y
                :nudge-width="200">
                <template v-slot:activator="{ on }"> 
                    <v-btn small class="px-0 mx-0 "  v-on="on" icon="" dark="">
                        <v-icon class="px-0 mx-0" color="primary">mdi-dots-vertical</v-icon>
                    </v-btn>
                </template> 
                <v-card min-height="150" color="white" class=""> 
                    <v-row class="py-9"> 
                        <v-col > 
                            <v-list  dense >                                                                                          
                                <v-list-item-title class="pb-1 ml-5 font-weight-bold">PAGES</v-list-item-title>
                                <v-divider />                                                                 
                                <v-list-item-group
                                    v-model="selectedItem"
                                    color=" "
                                >
                                    <v-list-item
                                        v-for="(item, i) in listItems"
                                        :key="i" :to="item.link"
                                    >                                           
                                        <v-list-item-icon>
                                            <v-icon v-text="item.iconName" 
                                            size="18" color="btn" 
                                            ></v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title v-text="item.text"></v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                        </v-col> 
                    </v-row>
                </v-card>
            </v-menu>         
            <v-app-bar-nav-icon  @click="drawer = !drawer"  
                color="white" dark class="hidden-sm-and-up"
            ></v-app-bar-nav-icon>                                    
        </v-app-bar> 
        <!-- <v-navigation-drawer> -->                    
        <v-navigation-drawer
            app temporary dark
            right  class=""
            v-model="drawer" color="warning" 
        >
            <v-list-item class="my-4">
                <v-list-item-content>
                <v-list-item-title class="title ">
                    WELCOME TO
                </v-list-item-title>
                <v-list-item-subtitle class="sub-title mt-1 text-h6">
                    KIPCOUNT
                </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-divider color="primary"></v-divider>
            <v-list dense nav class="">
                <v-list-item  v-for="menu in menulist" 
                :key="menu.title"
                router
                :to="menu.link">
                <v-list-item-content class="title">
                    <v-list-item-title >{{ menu.title }}</v-list-item-title>
                </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
        <!--end navigation drawer --> 
    </div>  
</template>

<script>
export default {
    components: {
    },
    data: () => ({
        drawer: false,
        menulist: [
            // {
            //     title: "Features",
            //     link: "/#features"
            // },
            // {
            //     title: "Why KPOS",
            //     link: "/#why-kpos"
            // },
            // {
            //     title: "Support",
            //     link: "/#support"
            // },
            {
                title: "Login",
                link: "/login",
                icon:"mdi-login",
            },
            {
                title: "Sign up ",
                link: "/sign-up",
                icon:"mdi-account-plus",
            },
        ],
        selectedItem: [],
        listItems: [
            // {iconName:'mdi-card-account-phone', text:'Features', link:'/#features'},
            // {iconName:'mdi-credit-card-outline', text:'Why KPOS', link:'/#why-kpos'},
            // {iconName:'mdi-credit-card-outline', text:'Support', link:'/#support'},
            {iconName:'mdi-login', text:'LOGIN', link:'/login'},
            {iconName:'mdi-account-plus', text:'SIGN UP', link:'/signup'},
            // {iconName:'mdi-information-variant', text:'ABOUT US', link:'/about'},
            // {iconName:'mdi-download-circle-outline', text:'DOWNLOAD', link:'/download'},
        ]
    }),
}
</script>