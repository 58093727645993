import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconFont: 'mdi'
    },
    theme: {
        themes: {
            light: {
                primary: "#0a345a",
                secondary: "#0A345A",
                accent: "#3a687f",
                card1:"#024A59",
                card2: "#0A345A",
                card3: "#184073",
                icons: "#f4cda3",
                icons1:"#46a2dc",
                navIcons: "#089cc1",
                btn: "#46a2dc",
                hoverColor:"#3a687f"
            }
        }
    }
});
