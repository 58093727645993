<template>
  <div id="Home" class=" "> 
    <div>
        <NavBar/>
    </div>       
    <div>
        <ToolBar/>
    </div>   
    <!-- // -->
    <v-layout align-center justify-center class="my-16">
        <v-container grid-list-xs>
            <div>
                <v-card flat  class="mt-  rounded-xl pa-4">
                    <div class="mb-4" v-for="(item, index) in KIPCOUNT_POS" :key="index">
                        <v-row align="center" justify="center">
                            <v-col cols="12" md="5">
                                <div class=" ">
                                    <v-card-text>
                                        <div class="mb-6 text-h5">
                                            <h2 class="lighten-3 primary--text">KipCount <span class=" ">Point of Sale System.</span></h2>
                                        </div>
                                        <div class="text-justify font-weight-bold" style="font-size:16px;">
                                            KipCount Point of Sale is a software that manages retailer transactions by 
                                            accepting purchases and expenses, make sales, monitor stock, and issue reports 
                                            for transaction in real time.                                             
                                        </div>                                    
                                        <div class="mt-6">
                                            <create-account />
                                        </div>
                                    </v-card-text>
                                </div>
                            </v-col>
                            <v-col cols="12" md="7"> 
                                <div align="center" class="hidden-xs-only">
                                    <v-img :src="item.src" width="550">
                                        <template v-slot:placeholder>
                                            <v-row class="fill-height" align="center" justify="center">
                                                <v-progress-circular 
                                                    indeterminate 
                                                    color="primary"
                                                ></v-progress-circular>
                                            </v-row>
                                        </template>
                                    </v-img>
                                </div>
                            </v-col>                            
                        </v-row>                                        
                    </div>
                </v-card>
            </div>                
        </v-container>
    </v-layout>
    <!--  -->
    <v-layout align-center justify-center class="my-16 grey lighten-3">
        <v-container grid-list-xs>
            <div class="py-16">
                <v-card class="py-12 grey lighten-3" flat>
                    <v-row>
                        <v-col cols="12">
                            <v-card-text>
                                <div class="text-h6 text-center mb-1">
                                    <h2 class="mb-">Manage your  business with our system</h2>
                                    <v-container grid-list-xs>
                                        <p class="px-16">Much more than just a Point Of Sale system, with KipCount POS you can also monitor and 
                                            manage some of the following key aspects in business.
                                        </p>                                        
                                    </v-container>
                                </div>
                            </v-card-text>
                        </v-col>
                        <v-col cols="12" md="3" v-for="(item, index) in SYSTEM_MANAGEMENT" :key="index">
                            <div>
                                <v-card color="grey lighten-3" class="rounded-xl pa-4" flat>
                                    <v-img :src="item.src" height="100%" aspect-ratio="1.1">
                                        <template v-slot:placeholder>
                                            <v-row class="fill-height" align="center" justify="center">
                                                <v-progress-circular 
                                                    indeterminate 
                                                    color="primary"
                                                ></v-progress-circular>
                                            </v-row>
                                        </template>
                                    </v-img>                                            
                                </v-card>
                                <div class="text-center mt-6">
                                    <v-card-text class="pa-0">
                                        <h2>{{item.info}}</h2> 
                                    </v-card-text>
                                </div>
                            </div>                            
                        </v-col>
                    </v-row>                    
                </v-card>
            </div>
        </v-container>
    </v-layout>       
    <!-- // -->
    <v-layout align-center justify-center class="mt-16">
        <v-container grid-list-xs>
            <v-card class="mt- rounded-xl pa-4" elevation="" flat>
                <v-layout justify-center align-center class=" ">
                    <v-container>
                        <div class="my-">
                            <v-row>
                                <v-col cols="12" >
                                    <v-card-text class="pa-0">
                                        <div class="mt- mb-6 text-center">
                                            <h4 class="text-h4 text-center">GET STARTED WITH KIPCOUNT</h4>
                                        </div>
                                    </v-card-text>              
                                </v-col>
                                <v-col cols="12" md="6" v-for="(item, index) in process" :key="index">      
                                    <v-card 
                                        color=" "
                                        class="rounded-xl" tile 
                                        height="100%"
                                        width="auto"                                                 
                                        :to="item.link"                                          
                                    >
                                        <div class="container">
                                            <v-card-text>
                                                <div align="center" justify="center">
                                                    <div>
                                                        <v-avatar
                                                            size="110"
                                                            color="primary"
                                                        >
                                                            <v-icon color="white"
                                                                size="50"
                                                            >{{item.iconName}}</v-icon>
                                                            <!-- <img :src="item.src" alt="alt"> -->
                                                        </v-avatar>
                                                    </div>
                                                    <div class="my-6">
                                                        <h2>{{item.title}}</h2>
                                                    </div>
                                                    <div>
                                                        {{item.info}}
                                                    </div>
                                                    <div class="my-6">
                                                        <v-btn :color="'primary'"
                                                            large
                                                            href="http://www.pos.kipcount.com" target="parent"
                                                        >{{item.btn}}</v-btn>
                                                    </div>
                                                </div>
                                            </v-card-text>  
                                        </div>
                                    </v-card> 
                                </v-col>
                            </v-row>
                        </div>
                    </v-container>
                </v-layout>                           
            </v-card>                 
        </v-container>              
    </v-layout>
    <!-- // -->
    <div class="mt-16">
        <download1/>
    </div>
    <!-- // -->
    <v-layout align-center justify-center class="mt-16 grey lighten-3">
        <v-container grid-list-xs>
            <div id="features">
                <v-card 
                    flat  class="my-16 rounded-xl">
                    <div class="" >
                        <v-row align="center" justify="center">  
                            <v-col cols="12" md="6" v-for="(item, index) in KEY_FEATURES_IMG" :key="index" > 
                                <v-img :src="item.src" width="550px">
                                    <template v-slot:placeholder>
                                        <v-row class="fill-height" align="center" justify="center">
                                            <v-progress-circular 
                                                indeterminate 
                                                color="primary"
                                            ></v-progress-circular>
                                        </v-row>
                                    </template>
                                </v-img>
                            </v-col>                         
                            <v-col cols="12" md="6">
                                <v-card height="100%" class="rounded-xl py-12" elevation="" flat>
                                <div >
                                    <v-list color=" ">
                                        <v-subheader class="text-h4 mb-4">KEY FEATURES</v-subheader>
                                        <v-list-item v-for="(item, index1) in KEY_FEATURES" :key="index1">
                                            <v-list-item-icon>
                                                <v-hover v-slot="{hover}">
                                                    <v-avatar
                                                        class=" "
                                                        size="50"
                                                        :color="hover?'primary':'hoverColor'"
                                                    >
                                                        <v-icon class="white--text"
                                                            :color="hover?'hoverColor':'primary'" size="25"
                                                        >{{item.icon}}</v-icon>
                                                    </v-avatar>
                                                </v-hover>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title class="text-h6">
                                                    {{item.title}}
                                                </v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>                                    
                                </div>
                            </v-card>
                            </v-col>                            
                        </v-row>                                        
                    </div>
                </v-card>
            </div>
        </v-container>
    </v-layout>
    <!-- /Why/ -->
    <v-layout align-center justify-center class="mt-16" id="why-kpos">
        <v-container grid-list-xs>
            <div>
                <v-row>
                    <v-col cols="12">
                        <v-card-text>
                            <div class="text-h5 text-center my-16">
                                <h2 class=" ">Why <span class="primary--text lighten-3">KipCount POS</span></h2>
                            </div>
                        </v-card-text>
                    </v-col>
                </v-row>
                <v-card flat>
                    <div class="mb-12" v-for="(item, index) in WHY_KPOS" :key="index" >
                        <v-row align="center" justify="center">  
                            <v-col cols="12" md="6">
                                <div class="hidden-xs-only">
                                    <v-img :src="item.src" width="400px">
                                        <template v-slot:placeholder>
                                            <v-row class="fill-height" align="center" justify="center">
                                                <v-progress-circular 
                                                    indeterminate 
                                                    color="primary"
                                                ></v-progress-circular>
                                            </v-row>
                                        </template>
                                    </v-img>
                                </div> 
                            </v-col>                         
                            <v-col cols="12" md="6">
                                <div class=" ">
                                    <v-card-text>
                                        <div class="mb-6 text-h6">
                                            <h2  class="text- primary--text">
                                                {{item.header}} 
                                            </h2>
                                        </div>
                                        <div class="text-justify text-h6">
                                            {{item.info}}
                                        </div>                                    
                                    </v-card-text>
                                </div>
                            </v-col>                            
                        </v-row>                                        
                    </div>                    
                </v-card>
                <!-- // -->  
                <v-card flat>
                    <div class=" " v-for="(item, index1) in WHY_KPOS1" :key="index1" >
                        <v-row align="center" justify="center">                                                       
                            <v-col cols="12" md="6">
                                <div class=" ">
                                    <v-card-text>
                                        <div class="mb-6 text-h6">
                                            <h2  class="text- primary--text">
                                                {{item.header}}
                                            </h2>
                                        </div>
                                        <div class="text-justify text-h6">
                                            {{item.info}}
                                        </div>                                    
                                    </v-card-text>
                                </div>
                            </v-col>    
                            <v-col cols="12" md="6"> 
                                <div class="hidden-xs-only">
                                    <v-img :src="item.src" width="400px">
                                        <template v-slot:placeholder>
                                            <v-row class="fill-height" align="center" justify="center">
                                                <v-progress-circular 
                                                    indeterminate 
                                                    color="primary"
                                                ></v-progress-circular>
                                            </v-row>
                                        </template>
                                    </v-img>
                                </div>
                            </v-col>                        
                        </v-row>                                        
                    </div>                                
                </v-card>  
                <!-- // -->  
                <v-card flat>
                    <div class="mb-12" v-for="(item, index1) in WHY_KPOS2" :key="index1" >
                        <v-row align="center" justify="center">                                                                                       
                            <v-col cols="12" md="6"> 
                                <div class="hidden-xs-only">
                                    <v-img :src="item.src" width="400px">
                                        <template v-slot:placeholder>
                                            <v-row class="fill-height" align="center" justify="center">
                                                <v-progress-circular 
                                                    indeterminate 
                                                    color="primary"
                                                ></v-progress-circular>
                                            </v-row>
                                        </template>
                                    </v-img>
                                </div>
                            </v-col>  
                            <v-col cols="12" md="6">
                                <div class=" ">
                                    <v-card-text>
                                        <div class="mb-6 text-h6">
                                            <h2  class="text- primary--text">
                                                {{item.header}}
                                            </h2>
                                        </div>
                                        <div class="text-justify text-h6">
                                            {{item.info}}
                                        </div>                                    
                                    </v-card-text>
                                </div>
                            </v-col>                      
                        </v-row>                                        
                    </div>                                
                </v-card> 
                <!-- // -->  
                <v-card flat>
                    <div class=" " v-for="(item, index1) in WHY_KPOS3" :key="index1" >
                        <v-row align="center" justify="center">                                                       
                            <v-col cols="12" md="6">
                                <div class=" ">
                                    <v-card-text>
                                        <div class="mb-6 text-h6">
                                            <h2  class="text- primary--text">
                                                {{item.header}}
                                            </h2>
                                        </div>
                                        <div class="text-justify text-h6">
                                            {{item.info}}
                                        </div>                                    
                                    </v-card-text>
                                </div>
                            </v-col>    
                            <v-col cols="12" md="6"> 
                                <div class="hidden-xs-only">
                                    <v-img :src="item.src" width="400px">
                                        <template v-slot:placeholder>
                                            <v-row class="fill-height" align="center" justify="center">
                                                <v-progress-circular 
                                                    indeterminate 
                                                    color="primary"
                                                ></v-progress-circular>
                                            </v-row>
                                        </template>
                                    </v-img>
                                </div>
                            </v-col>                        
                        </v-row>                                        
                    </div>                                
                </v-card> 
            </div>                
        </v-container>
    </v-layout>
    <!-- // -->
    <div>
        <GettingStarted />
    </div>
    <!-- // -->
    <v-layout align-center justify-center class="mt-16">
        <v-container grid-list-xs>
            <div class="my-12">
                <v-row>
                    <v-col cols="12">
                        <v-card-text>
                            <div class="text-h5 text-center mb-1">
                                <h2 class=" ">Subscription</h2>
                            </div>
                        </v-card-text>
                    </v-col>
                    <v-col cols="12">
                        <div align="center">
                            <v-card class="rounded-xl py-12" width="1000px">                        
                                <div>
                                    <v-card-text class="text- pl-10 primary--text text-h4">
                                        <v-icon size="45" color="primary">mdi-cash-clock</v-icon>
                                        Pricing
                                    </v-card-text>
                                </div>
                                <v-simple-table>
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                            <th class="text-center text-h6">
                                                Package
                                            </th>
                                            <th class="text-center text-h6">
                                                Price
                                            </th>
                                            <th class="text-center text-h6">
                                                Number of Users
                                            </th>
                                            <th class="text-center text-h6">
                                                Description
                                            </th>
                                            </tr>
                                        </thead>
                                        <tbody class="text-center">
                                            <tr
                                            v-for="item in PRICING"
                                            :key="item.name"
                                            >
                                            <td>{{ item.package }}</td>
                                            <td>{{ item.price }}</td>
                                            <td><v-icon class="pr-2">mdi-account</v-icon>{{item.numberOfUsers}}</td>
                                            <td>{{item.description}}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </v-card>   
                        </div>
                    </v-col>
                </v-row>
            </div>
        </v-container>
    </v-layout>
    <div align="center" class="mb-16">
        <create-account />
    </div>
    <!-- // -->
    <v-layout align-center justify-center class="mt-16 grey lighten-3">
        <v-container grid-list-xs>
            <div id="support" class=" ">
                <v-card flat class="my-16 grey lighten-3" >
                    <v-row>
                        <v-col cols="12">
                            <v-card-text>
                                <div class="text-h5 text-center mb-9">
                                    <h2 class=" ">Support</h2>
                                </div>
                            </v-card-text>
                    </v-col>
                        <v-col cols="12" md="4">
                            <div>
                                <v-card class="rounded-xl" flat>
                                    <v-img src="/images/all/support.webp" 
                                        height="100%" aspect-ratio="1">
                                    </v-img>                                
                                </v-card>
                            </div>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-card height="100%" class="rounded-xl grey lighten-3" elevation="" flat>
                                <div >
                                    <v-list color="grey lighten-3">
                                        <v-subheader class="text-h6 mt-4">CONTACT INFO</v-subheader>
                                        <v-list-item two-line v-for="(item, index) in SUPPORT" :key="index">
                                            <v-list-item-icon>
                                                <v-hover v-slot="{hover}">
                                                    <v-icon class="white--text"
                                                        :color="hover?'hoverColor':'primary'" size="30"
                                                    >{{item.icon}}</v-icon>
                                                </v-hover>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    {{item.title}}
                                                </v-list-item-title>
                                                <v-list-item-subtitle>
                                                    {{item.subtitle}}
                                                </v-list-item-subtitle>
                                                <v-list-item-subtitle>
                                                    {{item.description}}
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>                                    
                                </div>
                            </v-card>
                        </v-col>
                        <v-col cols="12" md="4">
                            <v-card class="rounded-xl pa-0" elevation=" " flat height="100%">
                                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15486.44758720874!2d33.7682322!3d-13.9816916!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb3e68650849c719e!2sMbera%20Solutions!5e0!3m2!1sen!2smw!4v1669360003271!5m2!1sen!2smw" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>                           
                            </v-card>
                        </v-col>
                    </v-row>                
                </v-card>
            </div>
        </v-container>
    </v-layout>
    <Footer1 /> 
  </div>
</template>
<script>
import NavBar from '@/components/NavBar.vue'
import ToolBar from '@/components/ToolBar.vue'
import Footer1 from '@/components/Footer1.vue'
// import Download from '@/components/Download.vue'
import Download1 from '@/components/Download1.vue'
import GettingStarted from '@/components/GettingStarted.vue'
import CreateAccount from '@/components/CreateAccount.vue'
export default {
    components: {
        NavBar,
        ToolBar,
        Footer1,
        // Download,
        Download1,
        GettingStarted,
        CreateAccount
    },
    data:() => ({                       
        // Login or Signing Up Process
        process: [
            {
                iconName:"mdi-account-check", src: './kipCount/KipCount.jpeg', 
                title: 'ALREADY HAVE AN ACCOUNT?', 
                info: 'LOGIN TO CONTINUE ENJOYING KIPCOUNT SERVICES.',
                btn:'Sign in',link:'/login',
                btnColor:"primary"                                  
            },{
                iconName:"mdi-account-plus", src: '', 
                title:"DON'T HAVE AN ACCOUNT?", 
                info: "REGISTER WITH KIPCOUNT TO ENJOY IT's SERVICES. ",
                btn:'Sign up',link:'/signup',
                btnColor:"success"                
            },
        ],                  
        PRICING:[
            {
                package:"Monthly",
                price:"MK15,000",
                numberOfUsers:"Unlimited",
                description:"Per Point of Sale"
            },
            {
                package:"Monthly",
                price:"MK30,000",
                numberOfUsers:"Unlimited",
                description:"Two Point of Sales"
            },
        ],
        SUPPORT:[
            {icon:"mdi-phone",title:"+265 993 259 202", subtitle:" ",description:"Phone Number(s)"},
            {icon:"mdi-email",title:"info@mberasolutions.com", subtitle:" ",description:"mberasolutions@gmail.com"},
            {icon:"mdi-map-marker",title:"Airwing", subtitle:" ",description:"Lilongwe."},                
        ]
    }),
    computed: {        
        kipCountImages() {
            return this.$store.getters.getKipCountImages
        },
        KIPCOUNT_POS() {
            return [
                {btn:"Explore More", src:this.kipCountImages+"k-pos.jpg"}
            ]
        },
        KEY_FEATURES_IMG() {
            return [
                {src:this.kipCountImages+"pos1.jpg"},            
            ]
        },
        KEY_FEATURES() {
            return [
                {icon:"mdi-store-clock",title:"Real time monitoring"},            
                {icon:"mdi-store-marker",title:"Multi-Store Management"},
                {icon:"mdi-store-cog",title:"Stock Management"},
                {icon:"mdi-receipt-text-check",title:"Bill & Invoice monitoring"},
                {icon:"mdi-trackpad-lock",title:"Tracking Expenses"},
            ]
        },
        SYSTEM_MANAGEMENT() {
            return [
                {src:this.kipCountImages+"employee.webp", info:"Employee"},
                {src:this.kipCountImages+"stock.webp", info:"Inventory "},
                {src:this.kipCountImages+"reports.webp", info:"Reports"},
                {src:this.kipCountImages+"shop.webp", info:"Customer"},
            ]
        },                      
        WHY_KPOS() {
            return [
              {
                src:this.kipCountImages+"price.jpg", 
                header:"Affordable",
                info:"K-POS  is free to set up and it's subscriptions are fair enough for every business to afford."
              },
            ]
        },
        WHY_KPOS1() {
            return [
                {
                    src:this.kipCountImages+"convenient.webp", 
                    header:"Convenient",
                    info:"K-POS is fast, easy to use & has features that are suitable to businesses."
                }
            ]
        },
        WHY_KPOS2() {
            return [
                {
                    src:this.kipCountImages+"time.webp", 
                    header:"Time saving",
                    info:"easy tracking of products hence, reduce customer waiting time."
                }
            ]
        },
        WHY_KPOS3() {
            return [
                {
                    src:this.kipCountImages+"customer.webp", 
                    header:"Helps with customer segmentation",
                    info:"Customers can be segmented into groups based on common characteristics in order to market to each group effectively and appropriately."
                }
            ]
        },
    }
}
</script> 