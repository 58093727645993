<template>
    <div>
        <v-layout align-center justify-center class="grey lighten-3 my-16">
            <v-container grid-list-xs>
                <v-card class="pa-16 grey lighten-3" flat>
                    <div class="my-9">
                        <v-card-text class=" ">
                            <v-row>
                                <v-col cols="12" md="6">                            
                                    <div class="text-h4">
                                        Get started today
                                    </div>                                      
                                </v-col>
                                <v-col cols="12" md="6">
                                    <div align="right center-xs-only">
                                        <CreateAccountVue/>
                                    </div>                           
                                </v-col> 
                            </v-row>                                                
                        </v-card-text>
                    </div>
                </v-card>
            </v-container>
        </v-layout>
    </div>
</template>

<script>
import CreateAccountVue from './CreateAccount.vue';
export default {
    components:{CreateAccountVue}
}
</script>

<style>

</style>