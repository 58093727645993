<template>
<div id="download" class="mt-16">
    <v-layout align-center justify-center>
        <v-flex xs12>
            <div align="center" v-for="(item, i) in DownloadBtn" :key="i">
                <v-hover v-slot="{hover}">
                    <v-btn 
                        :color="hover?'hoverColor':'primary'"
                        outlined rounded
                        class="py-3 px-4"
                        height="100%" width="300"
                    >
                        <div>
                            <v-icon class="mx-1"
                            >{{item.iconName}}</v-icon>
                            {{item.btnName}}
                        </div>
                    </v-btn>
                </v-hover>
            </div>
        </v-flex>
    </v-layout>
</div>  
</template>

<script>
export default {
    data: () => ({
        DownloadBtn: [
            {iconName: "mdi-file-download", btnName:"Download PDF"}
        ]
    })
}
</script>

<style>

</style>