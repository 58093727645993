<template>
    <v-layout class="my-16" >
        <v-toolbar color="primary" class="px-9" height="130">
            <v-toolbar-title>
                <v-card-text primary-title
                >
                    <v-hover v-slot="{hover}">
                        <div class="white--text text-h6 ">
                            <!-- <span class="">KipCount</span>  -->
                            <span :class="hover?'text-h py-16 hoverColor--text':'py-16 white--text'"> Make Every</span> 
                            <span :class="hover?'text-h py-16 hoverColor--text':'py-16 white--text'">Penny Count</span>                     
                        </div>
                    </v-hover>
                </v-card-text>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <div v-for="(item, index) in TOOLBAR_ICONS" :key="index" class="fill-height hidden-xs-only">
                    <v-hover v-slot="{hover}">
                        <v-card-text>
                            <v-icon :color="hover? 'hoverColor':'white'"
                                class="my-12" size="25"
                            >{{item.iconName}}</v-icon>                            
                        </v-card-text>
                    </v-hover>
                </div>
            </v-toolbar-items> 
        </v-toolbar>
    </v-layout>
</template>

<script>
export default {
    components: {},
    data: () => ({
        TOOLBAR_ICONS:[
            {iconName:"mdi-network-pos"},{iconName:"mdi-account-credit-card"},
            {iconName:"mdi-clock-outline"},
        ], 
    })
}
</script>

<style>

</style>