<template>
  <v-app>
    <v-layout fill-height>
      <ActivityShow v-if="show" />
      <ActivityMain v-else />
    </v-layout>
  </v-app>
</template>

<script>
let activity = "App";
import ActivityShow from "./views/mainActivity/ActivityShow.vue";
import ActivityMain from "./views/mainActivity/ActivityMain.vue";

export default {
  name: activity,
  components: 
    {  
      ActivityShow, 
      ActivityMain
    },
    data: () => ({
      show:true,
      
    }),
    created(){
    let self = this
    setTimeout(()=>{
        self.startTime=true
    }, 2500); 
  },
  mounted(){  
    let self = this  
    document.onreadystatechange = ()=>{
      if (document.readyState == "complete") { 
          if (self.startTime===true) {
            self.startApp(500) 
          } else { 
            self.startApp(2500)        
          } 
      } 
    }
  },
  computed: { 
  },
  methods: { 
    startApp(t){
      var self=this
      setTimeout(()=>{
          self.show=false
      },t); 
    }, 
  },
};
</script>
