<template>
<div>
    <div class="text-h2">
        <v-hover v-slot="{hover}">
            <!-- <a href="http://" target="_blank" rel="noopener noreferrer"></a> -->
            <v-btn href="http://www.pos.kipcount.com" target="parent"   :color="hover?'hoverColor':'primary'"
                rounded outlined
                x-large elevation="10"
                width="170px" >
                Sign Up
                <v-icon class="ml-2">mdi-account-plus</v-icon>
                <!-- <v-icon>mdi-account-lock-open</v-icon> -->
            </v-btn>
        </v-hover>
    </div>
</div>
</template>

<script>
export default {

}
</script>

<style>

</style>